.container {
	--heigth: 4.8rem;

	position: sticky;
	top: 0;
	background-color: var(--main_color);
	z-index: 30;
	height: min(4.8rem, 4.8rem);

	display: flex;
}
